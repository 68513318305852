import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/main.css';
import Routes from "./routes";
import { interceptor } from './utils/Interceptor';
import ErrorBoundary from './utils/ErrorBoundary';
function App(props) {
  interceptor();
  return (
    <React.Fragment>
      <ErrorBoundary {...props}>
        <ToastContainer />
        <Routes {...props}/>
      </ErrorBoundary>
    </React.Fragment>
  );
}

App.propTypes = {
  history: PropTypes.object,
};

export default withRouter(App);

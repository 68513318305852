import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute";
import { CircularProgress } from "@material-ui/core";

// Import Routes
const Header = lazy(() =>
  import(/*webpackChunkName: "HeaderPage" */ "../containers/Header")
);
const Login = lazy(() =>
  import(/*webpackChunkName: "LoginPage" */ "../containers/Login")
);
const ResetPassword = lazy(() =>
  import(/*webpackChunkName: "HomePage" */ "../containers/ResetPassword")
);
// Admin Routes
const Organizations = lazy(() =>
  import(
    /*webpackChunkName: "OrganizationsPage" */ "../containers/Admin/Organizations"
  )
);

const Devices = lazy(() =>
  import(/*webpackChunkName: "DevicesPage" */ "../containers/Admin/Devices")
);

const Users = lazy(() =>
  import(/*webpackChunkName: "UsersPage" */ "../containers/Admin/Users")
);

const UploadApk = lazy(() =>
  import(/*webpackChunkName: "UploadApk" */ "../containers/Admin/UploadApk")
);

const ForgotPassword = lazy(() =>
  import(/*webpackChunkName: "ForgotPassword" */ "../containers/ForgotPassword")
);

const SelectDevices = lazy(() =>
  import(/*webpackChunkName: "Device" */ "../containers/SelectDevices")
);

const Dashboard = lazy(() =>
  import(/*webpackChunkName: "Dashboard" */ "../containers/Dashboard")
);

const PageNotFound = lazy(() =>
  import(/*webpackChunkName: "PageNotFound" */ "../containers/PageNotFound")
);
const Routes = (props) => {
  return (
    <Suspense
      fallback={
        <div className="lazy-loader">
          <CircularProgress />
        </div>
      }
    >
      <Header />
      <Switch>
        {/* Common Routes  */}
        <Route exact path="/" name="Login" render={() => (sessionStorage.getItem('token') ? (<Redirect to="/devices" />) : (<Login />))}/>
        <Route
          exact
          path="/forgot-password"
          name="ForgotPassword"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/reset/:token"
          name="ResetPassword"
          component={ResetPassword}
        />
        <PrivateRoute
          exact
          path="/devices"
          name="SelectDevice"
          component={SelectDevices}
          {...props}
        />
        <PrivateRoute
          exact
          path="/dashboard/:id/:deviceName"
          name="Dashboard"
          component={Dashboard}
          {...props}
        />

        {/* Admin Routes */}
        <PrivateRoute
          exact
          path="/admin/organizations"
          name="AdminOrganizations"
          component={Organizations}
          {...props}
        />
        <PrivateRoute
          exact
          path="/admin/devices"
          name="AdminDevices"
          component={Devices}
          {...props}
        />
        <PrivateRoute
          exact
          path="/admin/users"
          name="AdminUsers"
          component={Users}
          {...props}
        />
        <PrivateRoute
          exact
          path="/admin/update-apk"
          name="AdminUsers"
          component={UploadApk}
          {...props}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};
export default Routes;

export const initialState = {
    isListLoading: false,
    success: false,
    error: false,
    deviceList: {},
    isAddLoading: false,
    isDeleteLoading: false,
    isEditLoading: false,
};

export default function devicesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'GET_DEVICE_LIST_LOADING':
            return {
                ...state,
                isListLoading: true,
                success: false,
                error: false,
            };

        case 'GET_DEVICE_LIST_SUCCESS':
            return {
                ...state,
                isListLoading: false,
                success: true,
                deviceList: action.payload.data,
                error: false,
            };
        case 'GET_DEVICE_LIST_ERROR':
            return {
                ...state,
                isListLoading: false,
                success: false,
                error: true,
            };
        case 'ADD_DEVICE_LOADING':
            return {
                ...state,
                isAddLoading: true,
                success: false,
                error: false,
            };

        case 'ADD_DEVICE_SUCCESS':
            return {
                ...state,
                isAddLoading: false,
                success: true,
                error: false,
            };
        case 'ADD_DEVICE_ERROR':
            return {
                ...state,
                isAddLoading: false,
                success: false,
                error: true,
            };
        case 'DELETE_DEVICE_LOADING':
            return {
                ...state,
                isDeleteLoading: true,
                success: false,
                error: false,
            };

        case 'DELETE_DEVICE_SUCCESS':
            return {
                ...state,
                isDeleteLoading: false,
                success: true,
                error: false,
            };
        case 'DELETE_DEVICE_ERROR':
            return {
                ...state,
                isDeleteLoading: false,
                success: false,
                error: true,
            };
        case 'EDIT_DEVICE_LOADING':
            return {
                ...state,
                isEditLoading: true,
                success: false,
                error: false,
            };

        case 'EDIT_DEVICE_SUCCESS':
            return {
                ...state,
                isEditLoading: false,
                success: true,
                error: false,
            };
        case 'EDIT_DEVICE_ERROR':
            return {
                ...state,
                isEditLoading: false,
                success: false,
                error: true,
            };
        default:
            return {
                ...state,
            };
    }
}

import { superAdminPaths } from '../constants/adminContstants';

export const getCurrentTime = () => {
  const getMinutes = (minutes) => {
    if (parseInt(minutes) < 10) {
      minutes = "0" + minutes;
    }
    return minutes;
  };
  const currentTime = new Date();
  const timeOfDay = currentTime
    .toLocaleTimeString()
    .substr(currentTime.toLocaleTimeString().toString().length - 2);
  return (
    currentTime.getHours() +
    ":" +
    getMinutes(currentTime.getMinutes()) +
    " " +
    timeOfDay
  );
};

export const validatePassword=(pass)=>{
  const errors=[];
  if (pass.length < 8) {
      errors.push("digit"); 
  }
  if (pass.search(/[a-z]/) < 0) {
      errors.push("lowercase");
  }
  if (pass.search(/[A-Z]/) < 0) {
    errors.push("capital"); 
  }
  if (pass.search(/[0-9]/) < 0) {
      errors.push("number"); 
  }
  if (pass.search(/[!@#$%^&*]/) < 0) {
    errors.push("special"); 
  }
  return errors;
}

export const strContainsOnlySpaces=(str)=>{
  let whitespace=false;
  if (!str.replace(/\s/g, '').length) {
    whitespace=true;
  }
  return whitespace;
}
export const fieldValidator=(str)=>{
  let errorMsg="";
  let whiteSpace=strContainsOnlySpaces(str);
  if(whiteSpace){
    errorMsg="Entered value must not contain only whitespaces";
  }
  if(str.length<=1){
    errorMsg="Entered value should have atleast two characters";
  }
  if(str.length>40){
    errorMsg="Entered value should be less then 40 characters";
  }
  return errorMsg;
}

export const superAdminRouteValidation=(role,path)=>{
  let isAdminPath=false;
  let isValid=true;
  superAdminPaths && superAdminPaths.length>0 && superAdminPaths.map(item=>{
    if(item===path){
      isAdminPath=true;
    }
    return null;
  });
  if(isAdminPath && role!=="super_admin"){
    isValid=false;
  }
  return isValid;
}
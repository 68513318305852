export const initialState = {
    openModal: false,
    nextPath: ''
};

export default function selectDeviceReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'LEAVE_SESSION_MODAL':
            return {
                ...state,
                openModal: action.data.openModal,
                nextPath: action.data.path
            };
        default:
            return {
                ...state,
            };
    }
}

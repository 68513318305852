export const initialState = {
    isListLoading: false,
    success: false,
    error: false,
    orgList: {},
    isAddLoading: false,
    isDeleteLoading: false,
    isEditLoading: false,
};

export default function organizationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'GET_ORGANIZATION_LIST_LOADING':
            return {
                ...state,
                isListLoading: true,
                success: false,
                error: false,
            };

        case 'GET_ORGANIZATION_LIST_SUCCESS':
            return {
                ...state,
                isListLoading: false,
                success: true,
                orgList: action.payload.data,
                error: false,
            };
        case 'GET_ORGANIZATION_LIST_ERROR':
            return {
                ...state,
                isListLoading: false,
                success: false,
                error: true,
            };
        case 'ADD_ORGANIZATION_LOADING':
            return {
                ...state,
                isAddLoading: true,
                success: false,
                error: false,
            };

        case 'ADD_ORGANIZATION_SUCCESS':
            return {
                ...state,
                isAddLoading: false,
                success: true,
                error: false,
            };
        case 'ADD_ORGANIZATION_ERROR':
            return {
                ...state,
                isAddLoading: false,
                success: false,
                error: true,
            };
        case 'DELETE_ORGANIZATION_LOADING':
            return {
                ...state,
                isDeleteLoading: true,
                success: false,
                error: false,
            };

        case 'DELETE_ORGANIZATION_SUCCESS':
            return {
                ...state,
                isDeleteLoading: false,
                success: true,
                error: false,
            };
        case 'DELETE_ORGANIZATION_ERROR':
            return {
                ...state,
                isDeleteLoading: false,
                success: false,
                error: true,
            };
        case 'EDIT_ORGANIZATION_LOADING':
            return {
                ...state,
                isEditLoading: true,
                success: false,
                error: false,
            };

        case 'EDIT_ORGANIZATION_SUCCESS':
            return {
                ...state,
                isEditLoading: false,
                success: true,
                error: false,
            };
        case 'EDIT_ORGANIZATION_ERROR':
            return {
                ...state,
                isEditLoading: false,
                success: false,
                error: true,
            };
        default:
            return {
                ...state,
            };
    }
}

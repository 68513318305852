export const initialState = {
    isLoginLoading: false,
    success: false,
    error: false,
    loginloginAccountData: {},
};

export default function loginReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'PERFORM_LOGIN_LOADING':
            return {
                ...state,
                isLoginLoading: true,
                success: false,
                error: false,
            };

        case 'PERFORM_LOGIN_SUCCESS':
            return {
                ...state,
                isLoginLoading: false,
                success: true,
                loginAccountData: action.payload.data,
                error: false,
            };
        case 'PERFORM_LOGIN_ERROR':
            return {
                ...state,
                isLoginLoading: false,
                success: false,
                error: true,
            };
        default:
            return {
                ...state,
            };
    }
}

import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import authReducer from './authReducer';
import organizationReducer from './Admin/organizationsReducer';
import devicesReducer from './Admin/devicesReducer';
import usersReducer from './Admin/usersReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import updateApkReducer from './Admin/updateApkReducer';
import selectDeviceReducer from './selectDeviceReducer';
import saveChatReducer from './saveChatReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
    login: loginReducer,
    auth: authReducer,
    adminOrg : organizationReducer,
    adminDevice : devicesReducer,
    adminUser : usersReducer,
    forgotPassword : forgotPasswordReducer,
    resetPassword : resetPasswordReducer,
    updateApk: updateApkReducer,
    selectDevice: selectDeviceReducer,
    chat: saveChatReducer,
    dashboard: dashboardReducer
});
  
export default rootReducer;
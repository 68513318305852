export const organizations = [
  {
    id: 1,
    name: "Test Org1",
  },
  {
    id: 2,
    name: "Test Org2",
  },
  {
    id: 3,
    name: "Test Org3",
  },
];

export const devices = [
  {
    id: 1,
    deviceName: "Device 1",
    deviceId: "1111-1111",
  },
  {
    id: 2,
    deviceName: "Device 2",
    deviceId: "2222-1111",
  },
  {
    id: 3,
    deviceName: "Device 3",
    deviceId: "3333-1111",
  },
];

export const users = [
  {
    id: 1,
    firstName: "user1",
    lastName: "lastname",
    prefix: "Dr.",
    email: "user1@yopmail.com",
    password: "test@123",
    enabledDevices: [
      {
        id: 1,
        deviceId: "1111-1111",
        deviceName: "Device 1",
      },
    ],
    organization: {
      id: 1,
      orgName: "Test Org",
    },
  },
  {
    id: 2,
    firstName: "user2",
    lastName: "lastname",
    prefix: "Mr.",
    email: "user2@yopmail.com",
    password: "test@123",
    enabledDevices: [
      {
        id: 1,
        deviceId: "2222-1111",
        deviceName: "Device 2",
      },
    ],
    organization: {
      id: 2,
      orgName: "Test Org 2",
    },
  },
  {
    id: 3,
    firstName: "user3",
    lastName: "lastname",
    prefix: "Mrs.",
    email: "user3@yopmail.com",
    password: "test@123",
    enabledDevices: [
      {
        id: 1,
        deviceId: "3333-1111",
        deviceName: "Device 3",
      },
    ],
    organization: {
      id: 3,
      orgName: "Test Org 3",
    },
  },
];

export const superAdminPaths = [
  "/admin/organizations",
  "/admin/devices",
  "/admin/users",
  "/admin/update-apk",
];

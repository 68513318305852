export const initialState = {
    isLoginLoading: false,
    success: false,
    error: false,
    userData: {},
    isLogoutLoading: false,
    enableAdminSwitch: false
};

export default function authReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'LOGIN_CHECK_LOADING':
            return {
                ...state,
                isLoginLoading: true,
                success: false,
                error: false,
            };

        case 'LOGIN_CHECK_SUCCESS':
            return {
                ...state,
                isLoginLoading: false,
                success: true,
                userData: action.payload.data,
                error: false,
            };
        case 'LOGIN_CHECK_ERROR':
            return {
                ...state,
                isLoginLoading: false,
                success: false,
                error: true,
            };
        case 'LOGOUT_LOADING':
            return {
                ...state,
                isLogoutLoading: true,
                success: false,
                error: false,
            };

        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                isLogoutLoading: false,
                success: true,
                userData: {},
                error: false,
            };
        case 'LOGOUT_ERROR':
            return {
                ...state,
                isLogoutLoading: false,
                success: false,
                error: true,
            };
        case 'ADMIN_SWITCH':
            return {
                ...state,
                enableAdminSwitch: action.enableAdminSwitch
            };
        default:
            return {
                ...state,
            };
    }
}

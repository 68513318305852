export const initialState = {
    isLoading: false,
    success: false,
    error: false,
    data: {},
};

export default function forgotPasswordReducer(state = initialState, action = {}) {
    switch (action.type) {
        case 'FORGOT_PASSWORD_LOADING':
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };

        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                isLoading: false,
                success: true,
                loginAccountData: action.payload.data,
                error: false,
            };
        case 'FORGOT_PASSWORD_ERROR':
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
            };
        default:
            return {
                ...state,
            };
    }
}

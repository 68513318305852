import React from "react";
import { Box } from "@material-ui/core";
import logo from "../assets/images/zeriscope_logo.png";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class EMWErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: true,
    });
  }

  render() {
    const { error } = this.state;
    const backHandler = () => {
      this.setState({
        error: false,
      });
      this.props.history.push(`/`);
    };
    return error ? (
      <>
        <Box display="flex" alignItems="center" justifyContent="center" className="error-boundary-section">
          <div className="error-contaniner">
            <img src={logo} alt="Zeriscope Logo"/>
            <h2>We have encountered an error!</h2>
            <h5>Sorry for the inconvenience caused.</h5>
            <Box display="flex" alignItems="center" justifyContent="center" >
              <ArrowBackIosIcon onClick={backHandler}/>
              <p  onClick={backHandler}>Go Back To Select Device Screen</p>
            </Box>
          </div>
        </Box>
      </>
    ) : (
      this.props.children
    );
  }
}

export default EMWErrorBoundary;

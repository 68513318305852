import api from './Api';

export function interceptor() {

  api.interceptors.request.use((config) => {
    const localToken = sessionStorage.getItem('token');
    let customConfig;
    
    if (localToken) {
      customConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${localToken}`,
        },
      };
    } else {
      customConfig = { ...config,
       headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
    }
    return customConfig;
  }, error => Promise.reject(error));
}

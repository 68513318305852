import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { auth, authLogout, setEnableAdmin } from "../containers/actions/authAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { superAdminRouteValidation } from '../utils/Helpers';
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { history, location } = rest;
  const [localToken, setLocalToken] = useState(null);
  const [role, setRole] = useState("");
  //  call api to validate login access
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(auth())
      .then((resp) => {
        const isLoggedIn = resp?.value?.data?.user;
        if (isLoggedIn) {
          setLocalToken(true);
          setRole(isLoggedIn?.role);
        } else {
          dispatch(authLogout());
          dispatch(setEnableAdmin(false));
          setLocalToken(false);
          setRole("");
          sessionStorage.removeItem("token");
        }
      })
      .catch((error) => {
        dispatch(authLogout()).then(() => {
          sessionStorage.removeItem("token");
          setLocalToken(false);
          setRole("");
          dispatch(setEnableAdmin(false));
          const resp = error?.response?.data;
          if (resp?.isPasswordExpired && resp?.token) {
            const errorMsg = error?.response?.data?.message;
            toast.error(
              errorMsg
                ? errorMsg
                : "Something went wrong! Please try again in sometime."
            );
            history.push(`/reset/${resp.token}`);
          }
        }).catch(()=>{
          setLocalToken(false);
          sessionStorage.removeItem("token");
          setLocalToken(false);
          setRole("");
        });
      });
  }, [dispatch, location?.pathname, history]);

  return localToken == null ? null : (
    <Route
      {...rest}
      render={(props) =>
        (localToken === true && superAdminRouteValidation(role,location?.pathname)) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default connect()(PrivateRoute);
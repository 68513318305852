import api from '../../utils/Api';

export function auth() {
  return {
    type: 'LOGIN_CHECK',
    payload: api.get(`/api/users/login-check`),
  };
}


export function authLogout() {
    return {
      type: 'LOGOUT',
      payload: api.get(`/api/users/logout`),
    };
}

export function setEnableAdmin(value){
  return {
    type: 'ADMIN_SWITCH',
    enableAdminSwitch: value
  };
}